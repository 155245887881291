<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">ドットマネー</h3>
        <div class="title-left">
          <img src="../../../assets/images/dot-money.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">ドットマネー</h3>
            <p><span>◆ドットマネーとは？</span><br>
              2500万人が得している！国内最大級のポイント交換サービスです。<br>
              お手持ちのポイントをドットマネーにまとめて、現金やギフト券、電子マネーに1マネー＝１円で交換できます。交換手数料は一切無料です。</p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong>1pt → <span>1ドットマネー</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container has-text-indent d-inline-block">
          <p class="text-indent">※ ドットマネーを現金・ギフト券・ポイントに交換するにはSMS認証が必要になります。</p>
          <p class="text-indent">※ ドットマネー口座番号確認は<a @click.prevent="openUrl('https://d-money.jp/history')">こちら</a></p>
          <p class="text-indent">※ ドットマネーは有効期限があります。有効期限を過ぎると失効いたします。失効したマネーはご利用いただけません。<br>なお、失効予定はドットマネー通帳にてご確認いただけます。マネーの獲得方法によって失効までの期間が異なりますのでご注意ください。</p>
          <p class="text-indent">※ ドットマネーへの交換の前に必ず<a @click.prevent="openUrl('https://d-money.jp/term')">利用規約</a>をご覧ください。（Amebaログイン状態ででご確認をお願いします）</p>
          <p class="text-indent">※ ドットマネーへ交換するためにはAmebaに登録する必要がございます。</p>
          <p class="text-indent">※ ドットマネーへの交換申請後、交換完了までにAmebaを退会した場合、マネーは付与されませんのでご注意ください。</p>
          <p class="text-indent">※ 一度交換したポイントのキャンセルはできませんのであらかじめご了承ください。</p>
          <p class="text-indent">※ 同一人物が複数IDにてポイント交換した場合、当社が不正な申込みと判断した場合、交換手続きを無効とさせて頂きます。尚、その際にはポイントの返却はできませんのであらかじめご了承ください。</p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <button @click="handleBeforeExchangePoint"
                v-if="profile && token"
                class="btn-exchange-point">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
        </button>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>

      <!-- LIST QA -->
<!--      <div class="wrap-list-QA">-->
<!--        <QuestionAnswer class="list-QA container"-->
<!--                        :list-question-answer="EXCHANGE_POINT_QA"/>-->
<!--      </div>-->
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3 class="mb-1">ポイント交換先<br class="sp">&#12288;ドットマネー</h3>
        <p class="sub-title mb-4">ドットマネーへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="13"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        range_point: true
                       }"
                      vid="point">
          </InputField>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange money-ref">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true">
          </InputField>
          <span class="pc">ドットマネー</span>
        </div>

        <p class="text-instruct">交換するポイントを入力して交換ポイントを確定するボタンをクリックしてください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3 class="mb-1">ポイント交換先<br class="sp">&#12288;ドットマネー</h3>
        <p class="sub-title mb-4">ドットマネーへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange confirm">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span>付与</span>
          <strong>{{ pointExchange }}</strong>
          <span>ドットマネー</span>
        </div>

        <p class="text-instruct">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button v-if="confirm"
                @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>

        <button v-if="!confirm"
                class="disabled">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請が完了しました
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-exchange-point modal-center"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;ドットマネー</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ (dataComplete.amount || dataComplete.point) | numberWithCommas }}<span class="sp color-orange">マネー</span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange">マネー</span>
        </div>
        <p
          class="text-instruct"
          v-if="dataComplete.amount"
        >
          ※「ドットマネーby Amebaへ移動」ボタンからドットマネーのサイトへ遷移しドットマネーをお受け取りください。
        </p>
        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>

      <div
        class="btn-footer"
        slot="footer"
        v-if="dataComplete.amount"
      >
        <button class="btn-common"
                @click="redirectUrl">
          ドットマネー by Amebaへ移動
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification" />

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
    <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <!-- button in modal ostiaries_error -->
            <button
                    @click.prevent="handelRedirectUpdateInfo"
                    v-html="'会員情報入力'"
                    class="btn-into-modal"/>
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
// import QuestionAnswer from '@/components/QuestionAnswer'
// import { EXCHANGE_POINT_QA } from '@/enum/question-answer'
import { ChevronLeftIcon } from 'vue-feather-icons'
import Modal from '@/components/advertising-detail/Modal'
import InputField from '@/components/form/InputField'
import { mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import SNS from '@/mixins/sns.mixin'

export default {
  name: 'Index',

  mixins: [Common, SNS],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    // QuestionAnswer,
    ChevronLeftIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      confirm: true
    }
  },

  methods: {
    ...mapActions('dotMoney', ['confirmExchange', 'authDotMoney', 'moneyExchange', 'loginDotMoney']),

    async handleExchangePoint () {
      this.onExchangePoint(this.moneyExchange)
    },

    async sendDotMoneyRequest () {
      if (!this.profile.otp) {
        this.flagModalVerifyAuth = true
        return
      }
      // Is the account linked to dotmoney?
      const result = await this.confirmExchange()

      // An unknown error when call api
      if (result.status !== 200) {
        this.messageNotification = 'エラーが発生しました。<br>時間をおいてから再度お試しください。'
        this.flagModalNoti = true
        return
      }

      // not logged in yet
      if (result.data.status_code !== 200) {
        const windowReference = window.open('', '_self')
        const dotMoneyResult = await this.authDotMoney()
        windowReference.location = dotMoneyResult.data
        return
      }

      // has error about account
      if (result.data.body.status !== 'ACTIVE') {
        this.messageNotification = 'ご利用中の口座に問題が発生しております。<br/>詳しくはお問い合わせください。'
        this.flagModalNoti = true
        return
      }

      this.handleAuthenticatePhone()
    },

    handleBeforeExchangePoint () {
      this.handleAuthenticatePhone(null, null, this.sendDotMoneyRequest)
    },

    async redirectUrl () {
      const windowReference = window.open()
      const loginDotMoneyResult = await this.loginDotMoney()

      if (this.checkWebview()) {
        this.triggerEventApp(
          'OPEN_BROWSER',
          { url: loginDotMoneyResult.data }
        )
      } else windowReference.location = loginDotMoneyResult.data
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';

.modal-exchange-point .content-body {
  h3 {
    margin-bottom: 25px;
  }
}

.note-service .text-indent {
  text-indent: -1.3rem;
}
</style>
